<template>
  <b-card-actions
    ref="FavoriteList"
    title="Favoritos"
    action-refresh
    class="top-level"
    @refresh="refresh"
  >
    <b-row align-v="start">

      <b-col md="4">
        <b-form-group>
          <label>Pesquisar</label>
          <b-form-input
            v-model="filter"
            :disabled="state === 'loading'"
            placeholder="Pesquisar"
            type="text"
            class="d-inline-block"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <div>
      <b-card
        v-for="value in filterRows(rows)"
        :key="value[0]"
        class="mb-1 list-row"
      >
        <b-row align-v="center">
          <b-col
            cols="12"
            lg="6"
          >
            <span
              class="text-nowrap flex-parent"
            >
              <span class="flex-child long-and-truncated">{{ value[1].length }} - <span v-html="value[1][0].product.name.replace(toBold, '<b>$1</b>')" /></span>
            </span>

          </b-col>

        </b-row>
        <b-row
          v-for="(user, index) in value[1].map(elt => elt.user)"
          :key="index"
          :class="index % 2 ? '' : 'text-dark'"
          :style="index === (value[1].length - 1) ? '': 'border-bottom: 1px solid #ccc;'"
        >
          <b-col
            cols="12"
          >
            <span
              class="text-nowrap flex-parent p-0"
            >
              <span
                class="flex-child long-and-truncated"
                v-html="user.first_name ? `${user.first_name} ${user.last_name}`.replace(toBold, '<b>$1</b>') : user.email.replace(toBold, '<b>$1</b>')"
              /><span
                v-if="user.first_name"
                class="flex-child long-and-truncated d-none d-lg-inline"
                v-html="user.email.replace(toBold, '<b>$1</b>')"
              />
            </span>
          </b-col>
        </b-row>

      </b-card>
    </div>
  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker, BModal,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      state: 'ready',
      dir: false,
      filter: '',
    }
  },
  computed: {
    rows() {
      if (!this.$store.state.products.favorites) {
        return []
      }
      const obj = this.$store.state.products.favorites.reduce((result, line) => {
        const { id } = line.product
        result[id] = result[id] ?? []
        result[id].push(line)
        return result
      }, {})
      return Object.entries(obj).sort((a, b) => (b[1].length - a[1].length))
    },
    toBold() {
      return new RegExp(`(${this.filter})`, 'gi')
    },
  },
  created() {
    if (!this.$store.state.products.favorites.length) {
      this.refresh()
    }
    this.stockDate = (new Date()).toISOString().substring(0, 10)
    this.searchFilter = this.$store.state.products.filters.search ? this.$store.state.products.filters.search : ''
  },
  methods: {
    refresh() {
      this.$store.dispatch('products/getFavorites').finally(() => {
        this.state = 'ready'
        this.$refs.FavoriteList.showLoading = false
      })
    },
    filterRows(rows) {
      if (!this.filter) {
        return rows
      }
      return rows.filter(row => (
        row[1].map(elt => elt.user.email.toLowerCase().includes(this.filter.toLowerCase())).includes(true)
          || row[1].map(elt => `${elt.user.first_name.toLowerCase()} ${elt.user.last_name.toLowerCase()}`.includes(this.filter.toLowerCase())).includes(true)
          || row[1].map(elt => elt.product.name.toLowerCase().includes(this.filter.toLowerCase())).includes(true)
      ))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card-body{
  padding-top: 0;
  padding-bottom: 0;
}

.list-row:hover {
  background: rgb(226, 226, 226);
}
.row-selected {
  background: #004259 !important;
  color: white;
}

.negative-stock {
  background: rgb(116, 0, 0) !important;
  color: white !important;
}

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#reason{
  border-radius: 6px;
}
</style>
